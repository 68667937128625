import { useState, useEffect } from "react";
import { getTranslatedRoutesBySlug } from "@/lib/actions/apiRequests";
import { useGlobalContext } from "../globalContext";
import { ReplaceFrontendUrls } from "./replaceFrontendUrl";

export function useGetTranlatedRoute(slug: string) {
  const state = useGlobalContext();
  const { lang = "en" } = state;
  const [linkUrl, setLinkUrl] = useState("/");

  const getSlugForLanguage = (
    data: Record<string, any>,
    lang: string
  ): string | null => {
    return data[lang]?.slug ?? null;
  };
  const getRouteBySlug = async (slug: string) => {
    if (!slug) return;
    try {
      const { data: routes } = await getTranslatedRoutesBySlug(slug);
      if (!routes) return null;

      const route = getSlugForLanguage(routes, lang);
      if (route) {
        //console.log("route", ReplaceFrontendUrls(route));
        setLinkUrl(ReplaceFrontendUrls(route));
      }
    } catch (error) {
      console.error("Error fetching routes:", error);
    }
  };

  useEffect(() => {
    getRouteBySlug(slug);
  }, [slug, lang]);

  return linkUrl;
}
