'use client';

import { Heading, RichText } from 'oddsgate-ds';
import classNames from 'classnames';
import getBlockStyles from '@/lib/wordpress/blocks/getBlockStyles';
import { useMemo } from 'react';
import DOMPurify from 'isomorphic-dompurify';

export type IBlockParagraph = {
    attrs: {
        align?: 'left' | 'center' | 'right' | 'justify';
        anchor?: string;
        backgroundColorHex?: string;
        content?: string;
        textColor?: string;
        textColorHex?: string;
        className?: string;
        style?: any;
        animation?: string;
    };
};

export default function BlockParagraph({ attrs }: IBlockParagraph) {
    const { align, anchor, className, content, style, animation } = attrs || {};

    const sanitizedContent = useMemo(() => {
        const domPurifyConfig = {
            ADD_ATTR: ['target', 'rel'], // Allow `target` attribute
        };
        return DOMPurify.sanitize(content || '', domPurifyConfig);
    }, [content]);

    const paragraphStyle = useMemo(
        () => ({
            ...getBlockStyles({
                textAlign: align,
                style,
            }),
        }),
        [style, align]
    );

    const headingStyle = useMemo(
        () => ({
            ...getBlockStyles({ style }),
        }),
        [style]
    );

    const computedClassName = classNames(
        className,
        animation && `elements_animated ${animation}`
    );

    if (!content) {
        return null; // Avoid rendering empty elements
    }

    return (
        <>
            <Heading
                id={anchor || undefined}
                tag="p"
                className={computedClassName}
                style={headingStyle}
                dangerouslySetInnerHTML={{
                    __html: sanitizedContent,
                }}
            />

            {
                // TODO: experiência
            }
            {/* <RichText
                id={anchor || undefined}
                tag="p"
                className={classNames(
                    className,
                    animation && `elements_animated ${animation}`
                )}
                style={{
                    ...headingStyle,
                    ...paragraphStyle,
                }}>
                {content}
            </RichText> */}
        </>
    );
}
