import { getGetTranlatedRoute } from "./getTranslatedRoute";
/**
 * Replace the url in address based in the environment variables NEXT_PUBLIC_LINKS_TO_REPLACE and NEXT_PUBLIC_FE_URL
 * Should be called in the server side, right after the request to the wordpress
 * @param url
 * @returns string
 */
export function ReplaceFrontendUrls(url: string) {
  if (!process.env.NEXT_PUBLIC_LINKS_TO_REPLACE) return url;
  const NEXT_PUBLIC_FE_URL = process.env.NEXT_PUBLIC_FE_URL || "";

  // replace the links in the url for the frontend url
  // avoid replace like https://oddsgate.com.br to https://oddsgate.com.br/.br
  if (NEXT_PUBLIC_FE_URL && url.indexOf(NEXT_PUBLIC_FE_URL) !== 0) {
    // split the links to replace and remove the spaces
    const linksToReplace = process.env.NEXT_PUBLIC_LINKS_TO_REPLACE.split(
      ","
    ).map((link: string) => link.trim());

    linksToReplace.forEach((link: string) => {
      url = url.replace(link, NEXT_PUBLIC_FE_URL);
    });
  }

  // avoid double slash in the links
  url = url.replace(`${NEXT_PUBLIC_FE_URL}/`, NEXT_PUBLIC_FE_URL);

  if (process.env.NEXT_PUBLIC_DEFAULT_LANG) {
    // remove the default language from the beginning of the url
    if (url.indexOf(`/${process.env.NEXT_PUBLIC_DEFAULT_LANG}/`) === 0) {
      url = url.replace(`/${process.env.NEXT_PUBLIC_DEFAULT_LANG}/`, `/`);
    }

    // remove the default language from the beginning of the url, 2nd round
    url = url.replace(
      `${NEXT_PUBLIC_FE_URL}${process.env.NEXT_PUBLIC_DEFAULT_LANG}`,
      `${NEXT_PUBLIC_FE_URL}`
    );

    // remove double slash
    url = url.replace(`${NEXT_PUBLIC_FE_URL}/`, `${NEXT_PUBLIC_FE_URL}`);
  }

  return url;
}

/**
 * Replace the urls in the blocks from the wordpress
 * @param blocks blocks from the wordpress
 * @param lang language to translate
 * @returns the blocks with the urls replaced
 */

export async function ReplaceUrlsInBlocks(blocks: any[], lang: string) {
  // for loop to make sure that tasks run syncronous
  for (const block of blocks) {
    //  blocks.forEach(async (block) => {
    if (block.innerBlocks) {
      block.innerBlocks = await ReplaceUrlsInBlocks(block.innerBlocks, lang);
    }
    if (block.blocks) {
      block.blocks = await ReplaceUrlsInBlocks(block.blocks, lang);
    }

    if (block.attrs) {
      block.attrs = await ReplaceUrlsInAttrs(block.attrs, lang);
    }
  }

  return blocks;
}

/**
 * Replace the urls in the attributes from the wordpress
 * @param attrs attributes from the wordpress
 * @param lang language to translate
 * @returns the attributes with the urls replaced
 */
export async function ReplaceUrlsInAttrs(attrs: any, lang: string) {
  if (attrs?.grid) {
    for (const gridItem of attrs.grid.items) {
      //attrs.grid.items.forEach(async (gridItem: any) => {
      if (gridItem.slug) {
        // console.log("gridITem slug before", gridItem.slug);
        // //gridItem.slug = await getGetTranlatedRoute(gridItem.slug);
        // console.log(
        //   "gridITem slug after",
        //   await getGetTranlatedRoute(gridItem.slug, lang),
        //   "not replacing yet"
        // );

        gridItem.slug = ReplaceFrontendUrls(gridItem.slug);
      }
    }
  }

  if (attrs?.href?.url) {
    attrs.href.url = ReplaceFrontendUrls(attrs.href.url);
  }
  return attrs;
}
