//@ts-nocheck
"use client";

import {
  Column,
  LicenseCard,
  CardMarquee,
  Row,
  Spacer,
  useMediaMatch,
  variables,
} from "oddsgate-ds";
import { Fragment, useEffect, useRef, useState } from "react";

import { BlocksProps } from "@/lib/wordpress/_types/blocksStyle";
import DisplayImage from "@/components/common/DisplayImage/DisplayImage";
import { IPost } from "@/lib/wordpress/_types/archiveGrid";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import displayBlock from "@/lib/wordpress/blocks/displayBlock";
import { gsap } from "gsap";
import { styled } from "styled-components";
import { useGSAP } from "@gsap/react";

export type IBlockSliderLicenses = {
  attrs: {
    className?: string;
    grid?: {
      items: IPost[];
      total: number;
    };
  };
  innerBlocks: BlocksProps[];
};

const StyledGridWrapper = styled.div`
  padding: 1rem;

  & > div {
    align-items: center;
  }

  @media only screen and (min-width: ${variables.responsiveMedia}) {
    width: max-content;
    padding: 0;

    & > div {
      flex-wrap: nowrap;
      align-items: stretch;
      padding-right: 4rem;
    }
  }
`;

const StyledLicenseCard = styled(LicenseCard)`
  position: relative;
  /* width: 90dvw; */
  min-width: 18rem;
  white-space: normal;

  @media only screen and (min-width: ${variables.responsiveMedia}) {
    width: 26rem;
  }
`;

const StyledBadgeTag = styled.p<{ color: string }>`
  position: absolute;
  top: 0;
  left: 50%;

  background-color: ${({ color }) => color || variables.colors.primary50};
  color: white;
  border-radius: 24px;
  padding: 6px 12px;
  transform: translate(-50%, -50%);

  font-family: var(--body-fontFamily);
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
`;

interface IBadgeTag {
  tag: string;
  colorPicker: string;
}

const BadgeTag: React.FC<IBadgeTag> = ({ tag, colorPicker }) => {
  return <StyledBadgeTag color={colorPicker}>{tag}</StyledBadgeTag>;
};

export default function BlockSliderLicenses({
  attrs,
  innerBlocks,
}: IBlockSliderLicenses) {
  const wrapper = useRef<HTMLDivElement>();
  const scrollingElement = useRef<HTMLDivElement>();
  const contentElement = useRef<HTMLDivElement>();
  const tl = useRef();

  const { grid, className } = attrs || {};

  const [filteredPosts, setFilteredPosts] = useState<IPost[] | undefined>([
    ...grid?.items,
    ...grid?.items,
    ...grid?.items,
  ]);

  const isMobile = useMediaMatch();
  // useGSAP(() => {
  //   if (!isMobile) {
  //     const containerSet = gsap.quickSetter(scrollingElement?.current, "css");
  //     containerSet({
  //       x: contentElement.current.getBoundingClientRect().left + window.scrollX,
  //     })

  //     const wrapperWidth = scrollingElement.current?.firstChild?.clientWidth;

  //     tl.current = gsap.timeline({
  //       paused: true,
  //       defaults: { ease: "none", overwrite: true },
  //       scrollTrigger: {
  //         trigger: wrapper.current,
  //         pin: true,
  //         start: "top top",
  //         end: () => `+=${wrapperWidth}px`,
  //         // markers: true,
  //         scrub: 1,
  //         invalidateOnRefresh: true
  //       },
  //     })
  //       .to(scrollingElement?.current, {
  //         x: () => -(wrapperWidth - window.innerWidth),
  //       }, 0);
  //   }else{
  //     gsap.set([scrollingElement?.current], { clearProps: true })
  //   }

  // }, { dependencies: [isMobile], scope: wrapper });

  if (!attrs?.grid) return <></>;

  return (
    <div ref={wrapper as any}>
      <Spacer customHeight="8rem" />
      <Row>
        <Column md={1}></Column>
        <Column md={10}>
          <div ref={contentElement}>
            {!!innerBlocks?.length &&
              innerBlocks.map((block: BlocksProps, index: number) => {
                return (
                  <Fragment
                    key={`blocksliderLicenses-${block.blockName}-${index}`}
                  >
                    {displayBlock(block, index)}
                  </Fragment>
                );
              })}
          </div>
        </Column>
        <Column md={1}></Column>
      </Row>
      <Spacer customHeight="3rem" />
      <StyledGridWrapper ref={scrollingElement}>
        <CardMarquee speed={isMobile ? 25 : 200} repeatContent={5} gap="4rem">
          {filteredPosts &&
            filteredPosts.length !== 0 &&
            filteredPosts.map((post, i: number) => {
              return (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    marginTop: "3rem",
                  }}
                  key={`licenses-filteredPosts-${i}`}
                >
                  <StyledLicenseCard
                    key={`licenses-${i}`}
                    imageElement={
                      <DisplayImage
                        url={post?.image as string}
                        alt={post?.title ? (post?.title as string) : ""}
                        width={250}
                        height={250}
                      />
                    }
                    title={post.title as string}
                    description={post.excerpt as string}
                  />
                  {post?.acf?.tag && (
                    <BadgeTag
                      tag={post?.acf?.tag}
                      colorPicker={post?.acf?.picker}
                    />
                  )}
                </div>
              );
            })}
        </CardMarquee>
      </StyledGridWrapper>
      <Spacer customHeight="8rem" />
    </div>
  );
}
