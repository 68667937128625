"use client";

import React, { useEffect, useState } from "react";

import { BlocksProps } from "@/lib/wordpress/_types/blocksStyle";
import { IPost } from "@/lib/wordpress/_types/archiveGrid";
import { Tabs } from "oddsgate-ds";
import displayBlock from "@/lib/wordpress/blocks/displayBlock";

export type IBlockTabs = {
  attrs: {
    title?: string;
    vertical?: boolean;
    className?: string;
    style?: Record<string, unknown>;
  };
  innerBlocks: BlocksProps[];
};
export default function BlockTabs({ attrs, innerBlocks }: IBlockTabs) {
  const { title, vertical, className } = attrs || {};

  const menu =
    innerBlocks &&
    innerBlocks.length > 0 &&
    innerBlocks.map((block: IPost, i: number) => {
      return {
        id: i,
        label: block.attrs.title,
        content: (
          <div
            dangerouslySetInnerHTML={{
              __html: block.attrs.content,
            }}
          ></div>
        ),
      };
    });

  return (
    <Tabs
      title={title}
      menu={menu as any}
      vertical={true}
      className={className || ""}
    ></Tabs>
  );
}
